import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../Asserts/CSS/Sections/Section2.css"
// import car1 from "../../Asserts/Img/car4.jpg"
import car2 from "../../Asserts/Img/car6.jpg"
import car3 from "../../Asserts/Img/car4.png"
import { Link } from 'react-scroll';
import slogan from "../../Asserts/Img/slogan.jpg"

const Section2 = () => {
  
    return (
        <div id='home'>            
                <div className="head-text">
                    <div className="head-image">
                        <img src={car3} className='caro_img img-fluid' />
                    </div>
                    <div className="col-12 center__text">
                        <img src={slogan} className='img-fluid'/>
                        <h3 className='caro_text1 mb-3'>A Complete One Stop<br/>INTERIOR DECORATION SOLUTION PROVIDER</h3>
                       <Link to='contact'> 
                       <button class="contact_btn btn border-animation mt-0 mt-md-4">Contact Us</button>
                       </Link>
                    </div>
                </div>              
        </div>
    )
}

export default Section2