import React from 'react'
// import banner from "../../Asserts/Img/banner5.jpg"
import "../../Asserts/CSS/Sections/Section5.css"
import { Link } from 'react-scroll'


const Section5 = () => {
    return (
        <div className='promo-text-area'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='inner-promo-txt'>
                            <div class="d-md-flex justify-content-between align-items-center">
                                <div class="sec-title1 mb-md-0 mb-5">
                                    <h2 class="text-white">Do you want to continue with Professional interiors?</h2>
                                </div>
                                <div class="btn-area d-flex justify-content-md-start justify-content-center">
                                    <Link to='contact'>
                                        <button class="contact_btn btn border-animation mt-4">CONTACT US</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span class="banner-frame-one"></span>
                <span class="banner-frame-two"></span>
                <span class="banner-frame-three"></span>
                <span class="banner-frame-four"></span>
            </div>
            <div className='shape-top-left' data-aos="fade-down" data-aos-delay="1000">

            </div>
            <div className='shape-bottom-right ' data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-delay="1000">

            </div>
        </div>
    )
}

export default Section5