import React from 'react'
import { FiSend, FiPhoneCall } from "react-icons/fi"
import { GoLocation, GoMailRead } from "react-icons/go"
import { FiMail } from "react-icons/fi";
import { Link, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import "../../Asserts/CSS/Sections/Footer.css"
import logo from "../../Asserts/Img/logo.png"

const Footer = () => {
    return (
        <footer id='contact' class="bg-dark1 text-light footer1">
            {/* <div className='col-12 d-flex justify-content-end'>
                <img src={contact} width={60} style={{ margin: "20px" }} />
            </div> */}
            {/* <div class="fixed-shape">
                <img src="https://wordpressriverthemes.com/tanda/wp-content/themes/tanda/img/map.svg" alt="Shape" />
            </div> */}
            <div class="container">
                <div class="f-items">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 item">
                            <div class="f-item about">
                                <img src={logo} class="img-fluid footerlogo" width="200" />
                                <p>
                                    Our company specializes in interior decoration, offering a diverse range of services to transform spaces into aesthetically pleasing and functional environments.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 item px-5" style={{ marginTop: "100px" }}>
                            <div class="f-item link">
                                <h4 class="widget-title px-4">Company</h4>
                                <div class="menu-company-container">
                                    <ul id="menu-company" class="menu">
                                        <Link to="home" smooth={true} duration={100}>
                                            <li id="menu-item-312" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-312" >
                                                <a style={{cursor:"pointer"}}>Home</a>
                                            </li>
                                        </Link>
                                        <Link to="aboutus" smooth={true} duration={100}>
                                            <li id="menu-item-312" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-312">
                                                <a style={{cursor:"pointer"}}>About Us</a>
                                            </li>
                                        </Link>
                                        <Link to="services" smooth={true} duration={100}>
                                            <li id="menu-item-317" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-317">
                                                <a style={{cursor:"pointer"}}>Our Services</a>
                                            </li>
                                        </Link>
                                        <Link to="whyus" smooth={true} duration={100}>
                                            <li id="menu-item-314" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-314">
                                                <a style={{cursor:"pointer"}}>Why Us</a>
                                            </li>
                                        </Link>
                                        <Link to="gallery" smooth={true} duration={100}>
                                            <li id="menu-item-313" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-313">
                                                <a style={{cursor:"pointer"}}>Gallery</a>
                                            </li>
                                        </Link>
                                        <Link to="contact" smooth={true} duration={100}>
                                            <li id="menu-item-315" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-315">
                                                <a style={{cursor:"pointer"}}>Contact Us</a>
                                            </li>
                                        </Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div class="col-lg-2 col-md-6 item" style={{marginTop:"100px"}}>
                            <div class="f-item link">
                                <h4 class="widget-title px-4">Help</h4>
                                <div class="menu-solutions-container">
                                    <ul id="menu-solutions" class="menu">
                                        <li id="menu-item-319" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-319">
                                            <a>FAQ</a>
                                        </li>
                                        <li id="menu-item-321" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-321">
                                            <a>Terms & Conditions</a>
                                        </li>
                                        <li id="menu-item-320" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-320">
                                            <a>Privacy Policy</a>
                                        </li>
                                        <li id="menu-item-416" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-416">
                                            <a>Refund Policy</a>
                                        </li>
                                        <li id="menu-item-419" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-419">
                                            <a>Technologies</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                        <div class="col-lg-4 col-md-6 item" style={{ marginTop: "100px" }}>
                            <div class="f-item">
                                <h4 class="widget-title px-4">Contact Info</h4>
                                <div class="address">
                                    <ul>
                                        <li className='d-flex flex-row gap-3'>
                                            <div class="icon">
                                                <i class="flaticon-pin">
                                                    <GoLocation className='footerIcon' />
                                                </i>
                                            </div>
                                            <div class="info">
                                                C.R# 167364-1, Al-Qudaybiyah, Kingdom of Bahrain.
                                            </div>
                                        </li>
                                        <li className='d-flex flex-row gap-3 mt-3'>
                                            <div class="icon">
                                                <i class="flaticon-email-1">
                                                    <FiMail className='footerIcon' />
                                                </i>
                                            </div>
                                            <div class="info">
                                                <a href="">info@sid-bh.com<br/>sharinterior.bh@gmail.com</a>
                                            </div>
                                        </li>
                                        <li className='d-flex flex-row gap-3 mt-4'>
                                            <div class="icon">
                                                <i class="flaticon-telephone">
                                                    <FiPhoneCall className='footerIcon' />
                                                </i>
                                            </div>
                                            <div class="info">
                                                +973 3337 7861
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="footer-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 d-flex justify-content-center">
                            <p>© 2023. Design & Developed by DLK Technologies Pvt Ltd. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer