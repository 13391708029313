import LandingPage from "./Components/LandingPage/LandingPage";

function App() {
  return (
    <section>
      <section>
        <LandingPage/>
      </section>
    </section>
  );
}

export default App;
