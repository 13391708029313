import React from 'react'
import "../../Asserts/CSS/LandingPage/LandingPage.css"
import Section1 from '../Sections/Section1'
import Section2 from '../Sections/Section2'
import Section3 from '../Sections/Section3'
import Section4 from '../Sections/Section4'
import Section5 from '../Sections/Section5'
import Section6 from '../Sections/Section6'
import Section7 from '../Sections/Section7'
import Section8 from '../Sections/Section8'
import Footer from '../Sections/Footer'
import ContactUs from '../Sections/ContactUs'
import call from "../../Asserts/Img/call-now.gif"
import whatsapp from "../../Asserts/Img/whatsapp.png"

const LandingPage = () => {
  return (
    <div>     
      <div class="jsn-air-balloon-phone request_quote_phone">
        <a href="https://api.whatsapp.com/send?phone=+973 33377861&amp;text=Hi! I Would Like To Know More About Your Service." target="_blank" class="icon">
          <img src={whatsapp} alt="Shar Interior Decoration  call" title="Shar Interior Decoration  whatsapp" />
        </a>
      </div>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section7 />
      <Section8 />
      <Section5 />
      <Section6 />
      <ContactUs />
      <Footer />
    </div>
  )
}

export default LandingPage