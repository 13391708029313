import React from 'react'
import "../../Asserts/CSS/Sections/Section3.css"
import about from "../../Asserts/Img/about.jpg"
import {Link} from "react-scroll"

const Section3 = () => {
    return (
        <div id='aboutus' className='col-12 col-md-12 d-flex flex-column flex-md-row justify-content-center p-2 p-md-5'>
            <div className='col-12 col-md-6'>
                <img src={about} className='img-fluid' />
            </div>
            <div className='col-12 col-md-6 px-0 px-md-5'>
                <div className='col col-md-12 d-flex flex-column'>
                    <text className='trainingMainheading1'>About Us</text>
                    <h3 className='aboutHeading mt-2'>Welcome to Shar Interior Decoration W.L.L</h3>
                    <p className='mt-2 aboutPara'>Our company specializes in interior decoration, offering a diverse range of services to transform
                        spaces into aesthetically pleasing and functional environments. To ensure the success and
                        visibility of our business, we prioritize effective publicity and advertisement strategies tailored to
                        the interior decoration industry. Like strategic branding, online presence, visual marketing,
                        collaborations and partnerships, online advertising, customer referrals and testimonials, and local
                        marketing. By implementing these publicity and advertisement strategies, we aim to increase
                        brand awareness, attract a steady stream of qualified leads, and position ourselves as a trusted
                        and reputable provider of interior decoration services.
                    </p>
                    <Link to='contact'>
                    <button class="col-5 contact_btn btn border-animation mt-4">Contact Us</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Section3