import React, { useState } from 'react'
import "../../Asserts/CSS/Sections/Section6.css"
import pro1 from "../../Asserts/Img/1.jpg"
import pro2 from "../../Asserts/Img/2.jpg"
import pro3 from "../../Asserts/Img/3.jpg"
import pro4 from "../../Asserts/Img/4.jpg"
import pro5 from "../../Asserts/Img/5.jpg"
import pro6 from "../../Asserts/Img/6.jpg"
import pro7 from "../../Asserts/Img/7.jpg"
import pro8 from "../../Asserts/Img/8.jpg"
import pro9 from "../../Asserts/Img/9.jpg"
import pro10 from "../../Asserts/Img/10.jpg"
import pro11 from "../../Asserts/Img/11.jpg"
import pro12 from "../../Asserts/Img/12.jpg"
import pro13 from "../../Asserts/Img/13.jpg"
import pro14 from "../../Asserts/Img/14.jpg"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Section6 = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const featuresData = [
        {
            label: "Epoxy Flooring 1",
            img: pro1,
            index: 1
        },
        {
            label: "Wooden works",
            img: pro2,
            index: 2
        },
        {
            label: "Interior decoration",
            img: pro3,
            index: 3,
        },
        {
            label: "PVC Flooring",
            img: pro4,
            index: 4
        },
        {
            label: "Granite Cladding work",
            img: pro5,
            index: 5
        },
        {
            label: "Decorative Lighting work",
            img: pro6,
            index: 6
        },
        {
            label: "Feature wall",
            img: pro7,
            index: 7
        },
        {
            label: "Wooden and Marble works",
            img: pro8,
            index: 8
        },
        {
            label: "Wall Tiling work",
            img: pro9,
            index: 9
        },
        {
            label: "Handrail for staircase",
            img: pro10,
            index: 10
        },
        {
            label: "Gypsum ceiling works",
            img: pro11,
            index: 11
        },
        {
            label: "Gypsum False Ceiling",
            img: pro12,
            index: 12
        },
        {
            label: "Carpet flooring",
            img: pro13,
            index: 13
        },
        {
            label: "Gypsum False Ceiling work",
            img: pro14,
            index: 14
        },
    ]
    const [features, setFeatures] = useState(featuresData)
    return (
        <div id='gallery' className="more-features pt-90 pb-70 mt-5">
            <div class="container">
                <h6 className='mb-5 card7Title d-flex justify-content-center'>
                    Gallery
                </h6>
                {/* <div class="row"> */}
                {/* {features.map((data) => {
                        return (
                            <div class="col-lg-3 col-md-4 col-12 mb-30">
                                <div class="card bakix-features wow fadeInUp" >
                                    <img class="card-img-top" src={data.img} alt="Card image cap" />                                  
                                    <h4>{data.label}</h4>
                                </div>
                            </div>
                        )
                    })} */}
                <Carousel
                    responsive={responsive}
                    infinite={true}
                    // showDots={true}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                >
                    {features.map((data) => {
                        return (
                            <div class="col-12 mb-30 p-4">
                                {/* <div class="bakix-features wow fadeInUp" >
                                    <img src={data.img} alt="" />
                                    <h4>{data.label}</h4>
                                </div> */}
                                <div className='card bakix-features '>
                                    <img src={data.img} alt="" />
                                    <h4>{data.label}</h4>
                                </div>
                            </div>
                        )
                    })}
                </Carousel>
            </div>
        </div>
        // </div>
    )
}

export default Section6 