import React from 'react'
import "../../Asserts/CSS/Sections/Section4.scss"
import classTrain from "../../Asserts/Img/offer1.png"
import offer2 from "../../Asserts/Img/offer2.png"
import offer3 from "../../Asserts/Img/offer3.png"
import offer4 from "../../Asserts/Img/offer4.png"

const Cards = () => {
    return (
        <div>
            <div className='mt-5 col col-md-12 d-flex flex-md-row flex-column justify-content-center'>
                {/* <a href="#" class="col-12 col-md-4 card education mainCard mb-5">
                    <div class="overlay">
                    </div>
                    <div class="circle mt-4">
                        <img src={classTrain} className='circleImg' />
                    </div>
                    <h6 className='mt-4 cardText'>Interior Decoration</h6>
                    <p className='mb-4'>We offer a complete choice of design and Interior contracting solutions for office interiors, Shop fit-outs, Hotels, Restaurants, Villas and Apartments.</p>
                </a>
                <a href="#" class="col-12 col-md-4 card credentialing mainCard mb-5">
                    <div class="overlay"></div>
                    <div class="circle mt-4">
                        <img src={offer2} className='circleImg' />
                    </div>
                    <h6 className='mt-4 cardText' >DOCUMENTATION</h6>
                    <p className='mb-4'>100 % Satisfaction</p>
                </a>

                <a href="#" class="col-12 col-md-4 card human-resources mainCard mb-5">
                    <div class="overlay"></div>
                    <div class="circle mt-4">
                        <img src={offer4} className='circleImg' />
                    </div>
                    <h6 className='mt-4 cardText'>DELIVERY</h6>
                    <p className='mb-4'>On-time Delivery</p>
                </a> */}
                <div className='col-12 col-md-4  p-4'>
                    <div className='col-12 card'>
                        <a href="#" class="education mainCard">
                            <div class="overlay">
                            </div>
                            <div class="circle mt-4">
                                <img src={classTrain} className='circleImg' />
                            </div>
                            <h6 className='mt-4 cardText'>INTERIOR DECORATION WORKS</h6>
                            <p className='mb-4 '>We offer a complete choice of design and Interior contracting solutions for office interiors, Shop fit-outs, Hotels, Restaurants, Villas and Apartments.</p>
                        </a>
                    </div>
                </div>
                <div className='col-12 col-md-4  p-4'>
                    <div className='col-12 card'>
                         <a href="#" class="credentialing mainCard">
                            <div class="overlay">
                            </div>
                            <div class="circle mt-4">
                                <img src={classTrain} className='circleImg' />
                            </div>
                            <h6 className='mt-4 cardText'>FALSE CEILING DECORATION WORKS</h6>
                            <p className='mb-4'>All types of false ceiling works including Gypsum<br/> (Normal, waterproof & Fire rated), grid, insulated, Aluminum, wooden and other ceilings.</p>
                        </a>
                    </div>
                </div>
                <div className='col-12 col-md-4  p-4'>
                    <div className='col-12 card'>
                         <a href="#" class="human-resources mainCard">
                            <div class="overlay">
                            </div>
                            <div class="circle mt-4">
                                <img src={classTrain} className='circleImg' />
                            </div>
                            <h6 className='mt-4 cardText'>PAINTING WORKS</h6>
                            <p className='mb-4'>All types of painting work including all<br/> internal surfaces of walls & ceilings.</p>
                        </a>
                    </div>
                </div>
            </div>

            <div className='col col-md-12 d-flex flex-md-row flex-column justify-content-center'>                
                <div className='col-12 col-md-4  p-4'>
                    <div className='col-12 card'>
                        <a href="#" class="human-resources1 mainCard">
                            <div class="overlay">
                            </div>
                            <div class="circle mt-4">
                                <img src={classTrain} className='circleImg' />
                            </div>
                            <h6 className='mt-4 cardText'>Epoxy Painting Works</h6>
                            <p className='mb-4'>All types of Epoxy flooring and wall painting for<br/> Car Parking areas, Utility Rooms, Pump Room,<br/> Tank Room, staircases, and other common areas.</p>
                        </a>
                    </div>
                </div>
                <div className='col-12 col-md-4  p-4'>
                    <div className='col-12 card'>
                         <a href="#" class="credentialing mainCard">
                            <div class="overlay">
                            </div>
                            <div class="circle mt-4">
                                <img src={classTrain} className='circleImg' />
                            </div>
                            <h6 className='mt-4 cardText'>Wooden  Works</h6>
                            <p className='mb-4'>All types of decorative wooden works including doors, ceiling, cabinets, and furniture.</p>
                        </a>
                    </div>
                </div>
                <div className='col-12 col-md-4  p-4'>
                    <div className='col-12 card'>
                         <a href="#" class="human-resources2 mainCard">
                            <div class="overlay">
                            </div>
                            <div class="circle mt-4">
                                <img src={classTrain} className='circleImg' />
                            </div>
                            <h6 className='mt-4 cardText'>Lighting Works</h6>
                            <p className='mb-4'>All types of decorative and other<br/> lighting in internal areas.</p>
                        </a>
                    </div>
                </div>
            </div>


            <div className='col col-md-12 d-flex flex-md-row flex-column justify-content-center'>                
                <div className='col-12 col-md-4  p-4'>
                    <div className='col-12 card'>
                        <a href="#" class="education mainCard">
                            <div class="overlay">
                            </div>
                            <div class="circle mt-4">
                                <img src={classTrain} className='circleImg' />
                            </div>
                            <h6 className='mt-4 cardText'>Handrail Works</h6>
                            <p className='mb-4'>All types of handrails work for staircase,<br/> Balconies and wherever possible.</p>
                        </a>
                    </div>
                </div>
                <div className='col-12 col-md-4  p-4'>
                    <div className='col-12 card'>
                         <a href="#" class="credentialing mainCard">
                            <div class="overlay">
                            </div>
                            <div class="circle mt-4">
                                <img src={classTrain} className='circleImg' />
                            </div>
                            <h6 className='mt-4 cardText'>Granite & Marble Works</h6>
                            <p className='mb-4'>All types of Granite & Marble flooring<br/> and wall cladding.</p>
                        </a>
                    </div>
                </div>
                <div className='col-12 col-md-4  p-4'>
                    <div className='col-12 card'>
                         <a href="#" class="human-resources1 mainCard">
                            <div class="overlay">
                            </div>
                            <div class="circle mt-4">
                                <img src={classTrain} className='circleImg' />
                            </div>
                            <h6 className='mt-4 cardText'>Porcelain & Ceramic Tile Works</h6>
                            <p className='mb-4'>Floor and Wall tile works in accordance<br/> with the client requirements.</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cards


