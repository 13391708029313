import React from 'react'
import "../../Asserts/CSS/Sections/Section7.css"
import why from "../../Asserts/Img/about.jpg"
import { Link } from "react-scroll"

const Section7 = () => {
    return (
        <section className='col-12 Main7 mb-5'>
            <div className='container Submain7 d-flex flex-md-row flex-column p-4'>
                <div className='col-12 col-md-5'>
                    <div className='d-flex flex-column main7Card card' style={{ padding: "50px", marginTop: "-80px" }}>
                        <h6 className='card7Title'>What we do</h6>
                        <p className='aboutPara mt-3' style={{ fontSize: "18px" }}>We create brand awareness, showcase expertise, generate leads, build trust and credibility,
                            differentiate ourselves from competitors, foster relationships, and establish a strong online
                            presence. These efforts are essential for attracting clients, expanding the customer base, and
                            positioning the interior decoration company as a trusted and preferred choice in the industry.
                        </p>
                        <Link to='contact'>
                            <button class="col-7 contact_btn btn border-animation mt-4">Contact Us</button>
                        </Link>
                    </div>
                </div>
                <div className='col-12 col-md-7 p-5 d-none d-md-flex d-xl-flex d-lg-flex'>
                    <img src={why} className='img-fluid' />
                </div>
            </div>
        </section>
    )
}

export default Section7