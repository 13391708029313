import React from 'react'
import "../../Asserts/CSS/Sections/Section4.scss"
import Cards from '../Reusable/Cards'
import star from "../../Asserts/Img/page-star.png"

const Section4 = () => {
    return (
        <div id='services' className='trainingwholeMain mt-3'>
            <div className=''>
                <div>
                    <div className='star '>
                        <img _ngcontent-ng-c3722721297="" src={star} alt="page-star" class="page-star" />
                    </div>
                    <div className='col col-md-12 d-flex justify-content-center'>
                        <text className='trainingMainheading1'>Our Services</text>
                    </div>
                    <div className='d-flex justify-content-center mt-3' >
                        <div style={{ width: "50px", backgroundColor: "#e97b2d", height: "4px", boxShadow: '1px 2px 9px #e97b2d' }} />
                    </div>
                </div>
                <div className=''>
                    <Cards />
                </div>
            </div>
        </div>
    )
}

export default Section4