import React from 'react'
import "../../Asserts/CSS/Sections/Section7.css"

const Section8 = () => {
    return (
        <div id='whyus' className='Main8 mb-5'>
            <div className='container'>
                <h6 className='card7Title d-flex justify-content-center'>
                    Why us
                </h6>
                <p className='aboutPara mt-3' style={{ fontSize: "18px" }}>At our company, we strive to stand out by offering unique approaches to attract clients. One
                    notable way we achieve this is through the implementation of digitalization in our interior
                    decoration services. By harnessing the power of technology, we want to leverage tools like 3D
                    Max Designer to create stunning, lifelike visualizations of interior spaces, specifically focusing on
                    lighting design, trendy approach in decorations etc. This enables us to provide clients with a
                    realistic representation of their desired lighting and decoration setup, ensuring it aligns perfectly
                    with their vision. We also focus on the hotel industry and specifically on renovation projects and
                    other commercial, residential, events, trades shows etc. and other field which needs interior
                    decoration.

                </p>
                <p className='aboutPara mt-3' style={{ fontSize: "18px" }}>
                    In addition to our innovative lighting designs, we are excited to introduce the use of wallpaper as
                    an alternative to traditional paint. By incorporating wallpaper, we aim to elevate the aesthetic
                    appeal of the spaces we decorate, providing a cleaner and more distinctive look. This approach
                    allows us to offer our clients a broader range of options, enabling them to customize their
                    interiors according to their preferences and create a truly unique atmosphere.
                </p>
                <p className='aboutPara mt-3' style={{ fontSize: "18px" }}>Considering the significance of digitalization in our operations, we strongly believe that being part
                    of a digitalization incubator would greatly benefit our company. Such an incubator would provide
                    us with access to the latest technological advancements and resources in the digital realm. This
                    would enable us to further enhance our interior decoration services, streamline our consultancy
                    activities, and optimize the organization of day-to-day business.
                </p>
                <p className='aboutPara mt-3' style={{ fontSize: "18px" }}>
                    In a digitalized incubator, we would have the opportunity to collaborate and network with
                    likeminded professionals and experts in the digital field. This would foster a culture of
                    innovation and inspire us to explore new possibilities for our business.
                    Moreover, the incubator's support and guidance would assist us in navigating the ever-evolving
                    digital landscape, ensuring we stay at the forefront of the industry and continue to deliver
                    exceptional services to our clients.
                </p>
                <p className='aboutPara mt-3' style={{ fontSize: "18px" }}>
                    Overall, embracing digitalization in our interior decoration, publicity and advertisement activities
                    allows us to offer cutting-edge solutions and exceed client expectations. Joining a digitalization
                    incubator would provide us with invaluable resources and support, enabling us to harness the full
                    potential of digital technologies and propel our company to new heights of success.
                </p>
            </div>
        </div>
    )
}

export default Section8