import React from 'react'
import "../../Asserts/CSS/Sections/Section1.css"
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
// import Button from 'react-bootstrap/Button';
import ourlogo from "../../Asserts/Img/logo.png"
import { Link, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { Button } from "react-bootstrap"
import { FiMail, FiPhoneCall } from "react-icons/fi";
import { BsWhatsapp,BsInstagram,BsYoutube  } from "react-icons/bs";
// import { AiOutlineInstagram, AiFillYoutube } from "react-icons/ai";

const Section1 = () => {
    return (
        <div className='col col-12 col-md-12' id='about'>
            <div className='navbarstyle d-flex justify-content-center align-items-center'>
                <div className='col-12 container contact d-flex justify-content-center'>
                    <ul className='d-flex flex-row gap-4'>
                        <li className='d-flex flex-row gap-3 mt-3'>
                            <div class="icon">
                                <i class="flaticon-email-1">
                                    <FiMail className='footerIcon1' />
                                </i>
                            </div>
                            <div class="info">
                                <a href="">info@sid-bh.com , sharinterior.bh@gmail.com</a>
                            </div>
                        </li>
                        <li className='d-flex flex-row gap-3 mt-3'>
                            <div class="icon">
                                <i class="flaticon-email-1">
                                    <FiPhoneCall className='footerIcon1' />
                                </i>
                            </div>
                            <div class="info">
                                <a href=""> +973 3337 7861</a>
                            </div>
                        </li>
                        <li className='d-flex flex-row gap-3 mt-3'>
                            <a onClick={() => window.open("https://instagram.com/sharinterior?utm_source=qr")} class="icon">
                                <i class="flaticon-email-1">
                                    <BsInstagram  className='footerIcon1' />
                                </i>
                            </a>
                        </li>
                        <li className='d-flex flex-row gap-3 mt-3'>
                            <a onClick={() => window.open("https://youtube.com/@SHARINTERIORDECORATION?si=LyZS56HuPWc8pbR9")} class="icon">
                                <i class="flaticon-email-1">
                                    <BsYoutube  className='footerIcon1' />
                                </i>
                            </a>
                        </li>
                        <li className='d-flex flex-row gap-3 mt-3'>
                            <a href="https://api.whatsapp.com/send?phone=+973 33377861&amp;text=Hi! I Would Like To Know More About Your Service." target="_blank" class="icon">
                                <i class="flaticon-email-1">
                                    <BsWhatsapp className='footerIcon1' />
                                </i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='col col-md-12 navbarstyle1'>
                {/* <Navbar collapseOnSelect expand="lg" className="">
                    <div className='col col-md-2 px-2'>
                        <Navbar.Brand href="#home" style={{ display: "flex", justifyContent: "center" }}>
                            <img src={ourlogo} className='' width="70px"></img>
                        </Navbar.Brand>
                    </div>
                    <div className='col col-md-3 px-2'></div>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav " className='col-7'>
                        <div className='container col col-md-12 linking'>
                            <Nav className="me-auto">
                                <div className='d-flex flex-row container mainlinking gap-4'>
                                    <div className='col-8 d-flex flex-row justify-content-center gap-4'>
                                        <Link to="home" smooth={true} duration={100}>
                                            <Nav.Link >
                                                <h4 className='sublinking'>Home</h4>
                                            </Nav.Link>
                                        </Link>
                                        <Link to="aboutus" smooth={true} duration={100}>
                                            <Nav.Link >
                                                <h4 className='sublinking'>AboutUs</h4>
                                            </Nav.Link>
                                        </Link>
                                        <Link to="services" smooth={true} duration={100}>
                                            <Nav.Link>
                                                <h4 className='sublinking'>Our Services</h4>
                                            </Nav.Link>
                                        </Link>
                                        <Link to="whyus" smooth={true} duration={100}>
                                            <Nav.Link ><h4 className='sublinking'>Why Us</h4></Nav.Link>
                                        </Link>
                                        <Link to="gallery" smooth={true} duration={100}>
                                            <Nav.Link ><h4 className='sublinking'>Gallery</h4></Nav.Link>
                                        </Link>
                                    </div>                                                                 
                                        <Link to='contact' className='col-2 contactUs d-flex flex-row justify-content-center' href="https://youtube.com/c/shortcode" target="_blank">
                                            <span> </span>
                                            <span> </span>
                                            <span> </span>
                                            <span> </span>
                                            <div className='mt-2'>Contact Us</div>
                                        </Link>                                                                     
                                </div>
                            </Nav>
                        </div>
                    </Navbar.Collapse>
                </Navbar> */}
                <>
                    {['sm'].map((expand) => (
                        <Navbar key={expand} expand={expand} className="bg-body-tertiary">
                            <Container fluid>
                                <div className='col col-md-2 px-2'>
                                    <Navbar.Brand className='d-flex justify-content-start justify-content-md-center' href="#home">
                                        <img src={ourlogo} className='' width="70px"></img>
                                    </Navbar.Brand>
                                </div>
                                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                                <Navbar.Offcanvas
                                    id={`offcanvasNavbar-expand-${expand}`}
                                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                    placement="end"
                                >
                                    <Offcanvas.Header closeButton>
                                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                            <Navbar.Brand href="#home" style={{ display: "flex", justifyContent: "center" }}>
                                                <img src={ourlogo} className='' width="70px"></img>
                                            </Navbar.Brand>
                                        </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <Nav className="justify-content-end flex-grow-1 pe-3">
                                            <Link to="home" smooth={true} duration={100}>
                                                <Nav.Link >
                                                    <h4 className='sublinking'>Home</h4>
                                                </Nav.Link>
                                            </Link>
                                            <Link to="aboutus" smooth={true} duration={100}>
                                                <Nav.Link >
                                                    <h4 className='sublinking'>AboutUs</h4>
                                                </Nav.Link>
                                            </Link>
                                            <Link to="services" smooth={true} duration={100}>
                                                <Nav.Link>
                                                    <h4 className='sublinking'>Our Services</h4>
                                                </Nav.Link>
                                            </Link>
                                            <Link to="whyus" smooth={true} duration={100}>
                                                <Nav.Link ><h4 className='sublinking'>Why Us</h4></Nav.Link>
                                            </Link>
                                            <Link to="gallery" smooth={true} duration={100}>
                                                <Nav.Link ><h4 className='sublinking'>Gallery</h4></Nav.Link>
                                            </Link>
                                        </Nav>
                                        {/* <Link to='contact' className='col-5 col-md-2 contactUs d-flex flex-row justify-content-center' href="https://youtube.com/c/shortcode" target="_blank">
                                            <span> </span>
                                            <span> </span>
                                            <span> </span>
                                            <span> </span>
                                            <div className='mt-2'>Contact Us</div>
                                        </Link>                                     */}
                                        <Link to='contact'>
                                            <Button variant="outline-success">Contact Us </Button>
                                        </Link>
                                    </Offcanvas.Body>
                                </Navbar.Offcanvas>
                            </Container>
                        </Navbar>
                    ))}
                </>
            </div>
        </div >
    )
}

export default Section1